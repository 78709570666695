.container {
    display: flex;
    height: 100%;
}

.formContainer {
    width: 591px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #181730;
}

.formWrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    gap: 1rem;
    height: 579px;
    width: 400px;
}

.form {
    margin-top: 7rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    width: 100%;
}

.welcomeContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: radial-gradient(100% 100% at 57.16% 60.62%, #5c208a 0%, #5c208a 8.8%, #16162e 79.41%);
}

.welcome {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 716px;
}

.welcomeText {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 350px;
    text-align: left;
}

.welcomeDescription {
    color: white;
    font-size: 24px;
}

.welcomeTitle {
    background: linear-gradient(90deg, #9925cf 0%, #fd0173 100%);
    font-weight: 900;
    font-size: 56px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.welcomeLogo {
    display: flex;
    justify-content: right;
    width: 286px;
}

.forgetPassword {
    color: #fd0173;
    font-size: 14px;
    cursor: pointer;
}

.forgetPassword:hover {
    text-decoration: underline;
}

.submitButton {
    font-size: 14px !important;
    font-weight: bold !important;
    letter-spacing: 2px;
    color: white !important;
}

.show {
    display: flex !important;
}

.rule {
    display: flex;
    gap: 8px;
}

.passwordWrapper {
    width: 100%;
    position: relative;
}

.passwordRules {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.9);
    display: none;
    flex-direction: column;
    padding: 16px;
    border-radius: 4px;
    box-sizing: border-box;
    gap: 8px;
    color: black;
    font-size: 14px;
    width: 100%;
    z-index: 99999;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.5);
}

/* .submitButton:disabled {
    background-color: grey;
} */

.title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 500;
}

.backIcon {
    margin-left: auto;
}

.backToLogin {
    color: #fd0173;
    font-size: 14px;
    cursor: pointer;
}

input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 100px #181730 inset !important;
}

.loadingIcon {
    color: white !important;
    margin-right: 4px;
}

.forgotPasswordSuccessText {
    text-align: start;
}

.helperText {
    position: absolute;
    top: calc(100% - 6px);
    left: 0;
    font-size: 14px !important;
    color: red !important;
}
