html {
    height: 100%;
    background-color: #14172c;
}

body,
#root {
    margin: 0;
    font-family: 'Avenir';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: 0.12px;
    text-align: left;
    color: #ffffff;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.no-data {
    padding: 0px 15px 0px 15px;
    padding-top: 30px;
}
